import React, { useState } from "react";

// creating functional component ans getting props from app.js and destucturing them
export default function StepFive({ nextStep, handleFormData, prevStep, values }) {
    //creating error state for validation
    const [error, setError] = useState(false);
    const [response, setResponse] = useState(null);

    // after form submit validating the form data using validator
    const submitFormData = (e) => {
        e.preventDefault();

        const validate = (values) => {
            let errors = {};
            let errorMsg = 'This field is required!'

            if(values.whatIsSuccess1.trim() === '') {
                errors.whatIsSuccess1 = errorMsg;
            }
            if(values.whatIsSuccess2.trim() === '') {
                errors.whatIsSuccess2 = errorMsg;
            }
        
            return errors;
        };

        if(Object.keys(validate(values)).length === 0) {     
            // console.log(values);

            // function capitalize(word) {
            //     return word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            // }
              
            let toAddServices =  values.servicesToAdd.services.toString() ? values.servicesToAdd.services.toString() : 'User no input';

            let whereToAdd =  values.whereSocialMedia.addurl.toString() ? values.whereSocialMedia.addurl.toString() : 'User no input';

            let socialMediaAdd =  values.addSocialMedia.addsocialmedia.toString() ? values.addSocialMedia.addsocialmedia.toString() : 'User no input';

            // console.log(socialMediaAdd)
              
            //   console.log(capitalize(toAddServices.replaceAll('-', ' ')))

            // console.log(capitalize(toAddServices.replaceAll('-', ' ')), whereToAdd, socialMediaAdd)

            fetch('https://contact.googolweb.com/get-started/', {
                method: 'POST',
                // mode: 'no-cors',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "email": values.email,
                    "phone": values.phone,
                    "businessName": values.businessName,
                    "businessLocation": values.businessLocation,
                    "businessServices": values.businessServices,
                    "decisionMakers": values.decisionMakers,
                    "projectTimeframe": values.projectTimeframe,
                    "projectContent": values.projectContent,
                    "projectBudget": values.projectBudget,
                    "servicesToAdd": toAddServices,
                    "eCommerceToCreate": values.eCommerceToCreate,
                    "whereSocialMedia": whereToAdd,
                    "addSocialMedia": socialMediaAdd,
                    "goalNumber1": values.goalNumber1,
                    "goalNumber2": values.goalNumber2,
                    "goalNumber3": values.goalNumber3,
                    "goalNumber4": values.goalNumber4,
                    "goalNumber5": values.goalNumber5,
                    "customerDetails": values.customerDetails,
                    "reasonsCustomerVisit": values.reasonsCustomerVisit,
                    "designConcept1": values.designConcept1,
                    "designConcept2": values.designConcept2,
                    "designConcept3": values.designConcept3,
                    "designConcept4": values.designConcept4,
                    "whatIsSuccess1": values.whatIsSuccess1,
                    "whatIsSuccess2": values.whatIsSuccess2
                    // "token": token
                })
                })
                .then(res => res.json())
                .then(res => {
                    setResponse(res);
                // console.log(res);
            });

        } else {
            setError(validate(values));
        }
    };

    return (
        <div>
            <form onSubmit={submitFormData}>

                <h2 className='mb-5 text-center text-capitalize'>What is success</h2>

                <p className="col-12 col-md-12">
                <label htmlFor="whatIsSuccess1" className='form-label'>If we were to be celebrating success in 12 months time, what would that have to look like? How many website visitors? How many leads? How many sales? Be as descriptive as you possibly can.</label>
                <textarea 
                    className={`form-control ${error.whatIsSuccess1 && 'is-invalid'}`}
                    name="whatIsSuccess1"
                    id="whatIsSuccess1"
                    defaultValue={values.whatIsSuccess1}
                    rows="3"
                    onChange={handleFormData("whatIsSuccess1")}
                />
                {error.whatIsSuccess1 && (
                    <span className='invalid-feedback'>
                        {error.whatIsSuccess1}
                    </span>
                )}
                </p>

                <p className="col-12 col-md-12">
                <label htmlFor="whatIsSuccess2" className='form-label'>Finally, is there anything else you want to let us know?</label>
                <textarea 
                    className={`form-control ${error.whatIsSuccess2 && 'is-invalid'}`}
                    name="whatIsSuccess2"
                    id="whatIsSuccess2"
                    defaultValue={values.whatIsSuccess2}
                    rows="3"
                    onChange={handleFormData("whatIsSuccess2")}
                />
                {error.whatIsSuccess2 && (
                    <span className='invalid-feedback'>
                        {error.whatIsSuccess2}
                    </span>
                )}
                </p>

                <div className='col-12 text-center'>
                <button type="submit" onClick={prevStep} className="btn btn-orange default-padding me-3 shadow text-capitalize" style={{ width: '150px' }}> Previous </button>
                <button type="submit" className="btn btn-primary default-padding shadow text-capitalize" style={{ width: '150px' }}> Send </button>
                </div>

                {response && (
                    <div className='message'>
                        {response}
                    </div>
                )}

            </form>
        </div>
    );
};
